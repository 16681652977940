// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-index-js": () => import("./../../../src/pages/2021/index.js" /* webpackChunkName: "component---src-pages-2021-index-js" */),
  "component---src-pages-2021-program-js": () => import("./../../../src/pages/2021/program.js" /* webpackChunkName: "component---src-pages-2021-program-js" */),
  "component---src-pages-2022-index-js": () => import("./../../../src/pages/2022/index.js" /* webpackChunkName: "component---src-pages-2022-index-js" */),
  "component---src-pages-2022-program-js": () => import("./../../../src/pages/2022/program.js" /* webpackChunkName: "component---src-pages-2022-program-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-templates-emberfest-bands-js": () => import("./../../../src/templates/emberfest-bands.js" /* webpackChunkName: "component---src-templates-emberfest-bands-js" */)
}

